import { Container, Row, Col, Card, Image } from "react-bootstrap";
import ironMan from "../asets/image/ironman.jpg";
import spiderMan from "../asets/image/spiderman.jpg";
import batMan from "../asets/image/batman.jpg";

const Films = () => {
  return (
    <div className="portfolio">
      <Container>
        <br />
        <br />
        <h1 className="text-white text-center">All Films</h1>
        <br />
        <br />
        <Row className="text-white text-center align-items-center">
          <Col md="4" className="movieWraper" id="films">
            <Card className="bg-dark text-white p-2 m-1 movieImage">
              <Image src={ironMan} alt="Iron Movies" />
              <Card.Title className="text-center">Ironman</Card.Title>
              <Card.Text className="text-left">This is a wider card with supporting text below as a natural lead-in to additional content.</Card.Text>
              <Card.Text className="text-left">Last updated 3 mins ago</Card.Text>
            </Card>
          </Col>
          <Col md="4" className="movieWraper">
            <Card className="bg-dark text-white p-2 m-1 movieImage">
              <Image src={spiderMan} alt="Spiderman Movies" />
              <Card.Title className="text-center">Spiderman</Card.Title>
              <Card.Text className="text-left">This is a wider card with supporting text below as a natural lead-in to additional content.</Card.Text>
              <Card.Text className="text-left">Last updated 3 mins ago</Card.Text>
            </Card>
          </Col>
          <Col md="4" className="movieWraper">
            <Card className="bg-dark text-white p-2 m-1 movieImage">
              <Image src={batMan} alt="Batman Movies" />
              <Card.Title className="text-center">Batman</Card.Title>
              <Card.Text className="text-left">This is a wider card with supporting text below as a natural lead-in to additional content.</Card.Text>
              <Card.Text className="text-left">Last updated 3 mins ago</Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Films;
