import "./App.css";
import NavigationsBar from "./components/NavigationsBar";
import "./style/landingPage.css";
import Intro from "./components/Intro";
import Trending from "./components/Trending";
import Films from "./components/Film";
import SearchComponent from "./components/Parallax";
import { Parallax } from "react-parallax";
import back from "../src/asets/image/trns.jpg";

function App() {
  return (
    <div>
      <Parallax strength={700} bgImage={back} className="myBG">
        <div>
          <NavigationsBar />
          <Intro />
        </div>
      </Parallax>

      <div className="trending">
        <Trending />
      </div>

      <div className="films">
        <Films />
      </div>

      {/* <div>
        <SearchComponent />
      </div> */}
    </div>
  );
}

export default App;
