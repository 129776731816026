import { Navbar, Container, Nav } from "react-bootstrap";
import "../style/landingPage.css";
import { animated } from "react-spring";
import React, { useState, useEffect } from "react";
import { RiSearchLine } from "react-icons/ri";

const NavigationsBar = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Implementasi logika pencarian di sini (misalnya, memfilter data)
  };
  const [lastScroll, setLastScroll] = useState(0);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (currentScroll > lastScroll && !isNavbarHidden) {
        setIsNavbarHidden(true);
      } else if (currentScroll < lastScroll && isNavbarHidden) {
        setIsNavbarHidden(false);
      }

      setLastScroll(currentScroll);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNavbarHidden, lastScroll]);

  const navbarStyle = {
    transform: isNavbarHidden ? "translateY(-100%)" : "translateY(0)",
    transition: "transform 0.3s ease-in-out",
  };

  return (
    <animated.div className="navi fixed-top" style={navbarStyle}>
      <Navbar>
        <Container>
          <Navbar.Brand href="/">
            <i>LevFilms</i>
          </Navbar.Brand>
          <Nav>
            <div className="p-2">
              <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} className="search-input" />
              <RiSearchLine style={{ marginLeft: "5px" }} className="search-icon" />
            </div>
            <Nav.Link className="b" href="#trending">
              Trending
            </Nav.Link>
            <Nav.Link className="b" href="#films">
              Film
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </animated.div>
  );
};
export default NavigationsBar;
