import { Container, Row, Col, Button } from "react-bootstrap";
import "../style/landingPage.css";
import React from "react";
import { useSpring, animated } from "react-spring";

const Intro = () => {
  const props = useSpring({
    // Contoh penambahan transform
    from: { opacity: 0, transform: "translateY(-50px)" }, // Transform awal
    config: { duration: 1000 },
    to: { opacity: 1, transform: "translateY(0px)" },
  });
  return (
    <animated.div className="intro" style={props}>
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Col className="text-center align-items-center">
            <h1>Welcome To LevFilms</h1>
            <p>This is Website For Watching Films</p>
            <div className="IntroButton mt-3 text-center">
              <Button variant="dark" href="#trending">
                See More
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </animated.div>
  );
};

export default Intro;
