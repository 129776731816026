import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import ironMan from "../asets/image/ironman.jpg";
import axios from "axios";

const Trending = () => {
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/person/popular`, {
        params: {
          api_key: process.env.REACT_APP_KEY,
        },
      })
      .then((response) => {
        setMovies(response.data.results);
      });
  }, []);
  return (
    <div>
      <Container>
        <br />
        <br />
        <h1 className="text-white text-center">Trending Films</h1>
        <br />
        <br />
        <Row className="text-white text-center align-items-center">
          {movies.map((result, index) => {
            <Col md="4" className="movieWraper" id="trending" key={index}>
              <Card className="bg-dark text-white p-2 m-1 movieImage">
                <Image src={`${process.env.REACT_APP_IMGURL}/${result.poster_path}`} alt="Test" />
                <Card.Title className="text-center">{result.overview}</Card.Title>
                <Card.Text className="text-left">This is a wider card with supporting text below as a natural lead-in to additional content.</Card.Text>
                <Card.Text className="text-left">Last updated 3 mins ago</Card.Text>
              </Card>
            </Col>;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Trending;
